<template>
  <div class="table-responsive">
    <table class="table v-middle text-nowrap bg-transparent">
      <thead class="bg-light">
        <tr class>
          <th class="border-0">Data</th>
          <th class="border-0">Ora</th>
          <th class="border-0">ID Camera</th>
          <th class="border-0">Indirizzo</th>
          <th class="border-0">Azione</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(record, index) in tableData" :key="record.id">
          <td>
            <div class="d-flex align-items-center">
              <div class="mr-2">
                {{ record.data }}
              </div>
            </div>
          </td>
          <td>{{ record.ora }}</td>
          <td>{{ record.idCamera }}</td>
          <td>
            <b-button v-b-modal="`address-modal`" @click="loadAddress(record.lat, record.long)">{{ record.indirizzo }}</b-button>
          </td>
          <td>
            <b-button v-b-modal="`modal-${index}`">Vedi</b-button>

            <b-modal size="lg" :id="`modal-${index}`" title="Registrazione" class="modal-content" :ok-only="true" ok-title="Chiudi">
              <p class="my-4"><youtube @ready="ready" :video-id="record.link"></youtube></p>
            </b-modal>
          </td>
        </tr>
      </tbody>
    </table>

    <b-modal size="lg" :id="`address-modal`" title="Indirizzo camera" class="modal-content" :ok-only="true" ok-title="Chiudi">
      <div id="address-map"></div>
    </b-modal>

  </div>
</template>

<script>
import { getIdFromURL } from 'vue-youtube-embed'
import "leaflet/dist/leaflet.css"
export default {
	name:"SellingProduct",
    components: {},
    data(){
      return {
        tableData: [
          {
            id:1,
            data: '2023-03-02',
            ora: '16:32',
            idCamera: 12,
            indirizzo: 'via roma, 71',
            lat: 41.602071,
            long: 13.240374,
            link: `75H9EAvYN80`
          },
          {
            id:2,
            data: '2023-05-28',
            ora: '18:54',
            idCamera: 38,
            indirizzo: 'via delle pietre, 2',
            lat: 40.820925,
            long: 16.046351,
            link: 'nHnXbTRdjZE'
          },
          {
            id:3,
            data: '2022-12-30',
            ora: '12:02',
            idCamera: 192,
            indirizzo: 'via del consorzio, 24',
            lat: 37.828260,
            long: 14.508135,
            link: 'GDdO-Yxx7kY'
          },
          {
            id:4,
            data: '2023-01-31',
            ora: '15:30',
            idCamera: 23,
            indirizzo: 'via lazio, 14',
            lat: 40.551588,
            long: 9.242932,
            link: 'mxEAWoYHwic'
          },
          {
            id:5,
            data: '2023-06-24',
            ora: '20:45',
            idCamera: 56,
            indirizzo: 'via del comune, 80',
            lat: 44.739086,
            long: 9.752468,
            link: '2OvN6EI-38s'
          }
        ],
        marker: null,
        map: null
      }
    },
    methods:{
      getVideoId(url){
        console.log(getIdFromURL(url));
      },
      ready (event) {
        this.player = event.target
      },
      loadAddress(lat, long){

        const defaultIcon = new window.L.icon({
      iconUrl: require('../../../node_modules/leaflet/dist/images/marker-icon.png'),
      iconSize:     [25, 41],
        shadowSize:   [30, 65],
        iconAnchor:   [12, 41],
        shadowAnchor: [7, 65]
    });
        
        setTimeout(function(){
          const map = window.L.map('address-map', {
          center: [lat, long],
          zoom: 12,
        });

        window.L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
        }).addTo(map);

        this.marker = window.L.marker([lat, long], {icon: defaultIcon}).addTo(map);
        }, 300);
        
      }
    }
}    
</script>

<style>
.modal-content{
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-body{
  height: 55vh;
  width: 100%;
}
#address-map{
  height: 100%;
  width: 100%;
}
</style>