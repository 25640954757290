<template>
  <div>
    <SellingProduct />
    <br>
    <div class="video-grid">
      <!-- <div><youtube @ready="ready" video-id="75H9EAvYN80" :player-width="540" :mute="true" :player-vars="{ autoplay: 1, controls: 0, rel: 0, showinfo: 0, loop: 1 }"></youtube></div> -->
      <div><iframe id="ytplayer" type="text/html" width="640" height="360"
        :src="'https://www.youtube.com/embed/75H9EAvYN80?autoplay=1&controls=0&showinfo=0&loop=1&rel=0&mute=1&playlist=75H9EAvYN80'"
        frameborder="0"></iframe></div>
        <div><iframe id="ytplayer" type="text/html" width="640" height="360"
        :src="'https://www.youtube.com/embed/nHnXbTRdjZE?autoplay=1&controls=0&showinfo=0&loop=1&rel=0&mute=1&playlist=nHnXbTRdjZE'"
        frameborder="0"></iframe></div><div><iframe id="ytplayer" type="text/html" width="640" height="360"
        :src="'https://www.youtube.com/embed/GDdO-Yxx7kY?autoplay=1&controls=0&showinfo=0&loop=1&rel=0&mute=1&playlist=GDdO-Yxx7kY'"
        frameborder="0"></iframe></div><div><iframe id="ytplayer" type="text/html" width="640" height="360"
        :src="'https://www.youtube.com/embed/mxEAWoYHwic?autoplay=1&controls=0&showinfo=0&loop=1&rel=0&mute=1&playlist=mxEAWoYHwic'"
        frameborder="0"></iframe></div><div><iframe id="ytplayer" type="text/html" width="640" height="360"
        :src="'https://www.youtube.com/embed/2OvN6EI-38s?autoplay=1&controls=0&showinfo=0&loop=1&rel=0&mute=1&playlist=2OvN6EI-38s'"
        frameborder="0"></iframe></div><div><iframe id="ytplayer" type="text/html" width="640" height="360"
        :src="'https://www.youtube.com/embed/Gmzi-vd7zi0?autoplay=1&controls=0&showinfo=0&loop=1&rel=0&mute=1&playlist=Gmzi-vd7zi0'"
        frameborder="0"></iframe></div><div><iframe id="ytplayer" type="text/html" width="640" height="360"
        :src="'https://www.youtube.com/embed/VoThqGujx2M?autoplay=1&controls=0&showinfo=0&loop=1&rel=0&mute=1&playlist=VoThqGujx2M'"
        frameborder="0"></iframe></div>
<!-- 
      <div><youtube @ready="ready" video-id="nHnXbTRdjZE" :player-width="540" :mute="true" :player-vars="{ autoplay: 1, controls: 0, rel: 0, showinfo: 0, loop: 1 }"></youtube></div>
      <div><youtube @ready="ready" video-id="GDdO-Yxx7kY" :player-width="540" :mute="true" :player-vars="{ autoplay: 1, controls: 0, rel: 0, showinfo: 0, loop: 1 }"></youtube></div>
      <div><youtube @ready="ready" video-id="mxEAWoYHwic" :player-width="540" :mute="true" :player-vars="{ autoplay: 1, controls: 0, rel: 0, showinfo: 0, loop: 1 }"></youtube></div>
      <div><youtube @ready="ready" video-id="2OvN6EI-38s" :player-width="540" :mute="true"  :player-vars="{ autoplay: 1, controls: 0, rel: 0, showinfo: 0, loop: 1 }"></youtube></div>
      <div><youtube @ready="ready" video-id="Gmzi-vd7zi0" :player-width="540" :mute="true" :player-vars="{ autoplay: 1, controls: 0, rel: 0, showinfo: 0, loop: 1 }"></youtube></div>
      <div><youtube @ready="ready" video-id="VoThqGujx2M" :player-width="540" :mute="true" :player-vars="{ autoplay: 1, controls: 0, rel: 0, showinfo: 0, loop: 1 }"></youtube></div> -->
    </div>
  </div>
</template>


<script>
import SellingProduct from '../dashboard-components/SellingProduct'

export default {
  components: {
    SellingProduct
  },
  methods: {
    ready (event) {
        this.player = event.target
      },
  },
}
</script>

<style>
.video-grid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  row-gap: -10px;
  position: relative;
}

 
iframe,
object,
embed {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 35vh;
}
</style>

